import { S25ReservationUtil } from "./s25.reservation";
import { S25Util } from "../../../util/s25-util";
import { EventMircotI, ExpandedInfoI, WSReservations, EventDataMicroI } from "../EventMicroI";
import { Event } from "../../../pojo/Event";
import Approval = Event.Workflow.Task;
import { EventService, Etags } from "../../../services/event.service";
import { S25Profile, ProfileUseCode, RecordType, AdditionalTime, AddedTime } from "../ProfileI";
import { S25Reservation, S25ObjectReservation, S25RsReservation, S25RmReservation, ObjectType } from "../ReservationI";

export class S25ProfileUtil {
    public static setFromWs(wsProfiles: S25Profile[], wsExpandedInfo: ExpandedInfoI, wsApprovals?: Approval) {
        return S25Util.array.forceArray(wsProfiles).map((p: S25Profile) => {
            const rsrv = S25ReservationUtil.setFromWS(p, wsExpandedInfo, wsApprovals);
            return {
                itemId: p.profileId,
                itemName: p.name,
                comment: p.comments,
                expectedCount: p.expectedCount,
                registeredCount: p.registeredCount,
                occurrenceDefn: p.occurrenceDefn,
                reservations: rsrv,
            } as S25Profile;
        });
    }

    //TODO: implement needed util methods, a lot of these may already exist for the event form and can either be called here or used directly
    public static getAllReservations(profile: S25Profile) {
        return (profile.reservations as S25Reservation[]) || [];
    }

    public static getProfileName(profiles: S25Profile[], profileId: number) {
        const find = profiles.find((p) => p.itemId === profileId);
        return find?.itemName || null;
    }

    public static getProfileByProfileId(profiles: S25Profile[], profileId: number) {
        const find = profiles.find((p) => p.itemId === profileId);
        return find || undefined;
    }

    public static getProfileByRsrvId(profiles: S25Profile[], rsvsId?: number) {
        const find =
            profiles.find((profile: S25Profile) =>
                profile.reservations.some((reservation: S25Reservation) => reservation.itemId === rsvsId),
            ) || null;
        return find || null;
    }

    public static addReservation(profile: S25Profile, rsrv: S25Reservation) {
        return profile.reservations.push(rsrv);
    }

    public static async createEmptyProfile(startDate: Date, endDate: Date) {
        const profileId = await EventService.getNewProfileId();
        return {
            itemId: profileId,
            itemName: "New Segment",
            reservations: [] as S25Reservation[],
            occurrenceDefn: {
                recTypeId: RecordType.SingleDateTime,
                initStartDt: S25Util.date.toS25ISODateTimeStr(startDate),
                initEndDt: S25Util.date.toS25ISODateTimeStr(endDate),
            },
        };
    }

    public static async duplicateProfile(profile: S25Profile) {
        let newProfile = S25Util.deepCopy(profile);
        newProfile.profileId = await EventService.getNewProfileId();
        newProfile.itemName = `Copy of ${profile.itemName}`;
        newProfile.reservations = await S25ReservationUtil.duplicateReservations(
            profile.reservations as S25Reservation[],
            newProfile.profileId,
        );
        return newProfile;
    }

    // profile pram optional, if multiple  profiles, just update one profile,
    // edit additional time apply this method, might be slider change event start/end time can apply this
    public static normalizeProfileWSData(event: EventMircotI, profile?: S25Profile[]) {
        const normalizeProfile = profile ? profile : event.profiles;
        const eTags: Etags = { id: event.itemId, etag: event.etag };
        const normalizeData = normalizeProfile.map((p: S25Profile) => {
            return {
                profileId: p.itemId,
                name: p.itemName,
                comments: p.comment,
                occurrenceDefn: p.occurrenceDefn, // this might need to adjust, depends on how the add/edit data
                reservations: this.normalizeRsrvWSData(p.reservations as S25Reservation[]),
            } as S25Profile;
        });

        const data: EventDataMicroI = {
            items: [
                {
                    kind: "event",
                    id: event.itemId,
                    name: event.itemName,
                    state: event.state,
                    profiles: normalizeData,
                },
            ],
        };
        return [{ eTags: eTags, data: data }];
    }

    //// normalize profile S25Reservation with array Data
    public static normalizeRsrvWSData(rsrv: S25Reservation[]) {
        return S25Util.array.forceArray(rsrv).map((r: S25Reservation) => {
            return {
                rsrvId: r.itemId,
                state: r.state === "active" ? 1 : 99,
                occurrence: S25Util.date.toS25ISODateStr(r.preEventStart),
                rsrvStartDt:
                    S25Util.date.toS25ISODateTimeStr(r.setupStart) ||
                    S25Util.date.toS25ISODateTimeStr(r.preEventStart) ||
                    S25Util.date.toS25ISODateTimeStr(r.eventStart),
                evStartDt: S25Util.date.toS25ISODateTimeStr(r.eventStart),
                evEndDt: S25Util.date.toS25ISODateTimeStr(r.eventEnd),
                rsrvEndDt:
                    S25Util.date.toS25ISODateTimeStr(r.takeDownEnd) ||
                    S25Util.date.toS25ISODateTimeStr(r.postEventEnd) ||
                    S25Util.date.toS25ISODateTimeStr(r.eventEnd),
                spaces: this.normalizeObjectsData(r.locations, "location"),
                resources: this.normalizeObjectsData(r.resources, "resource"),
            } as WSReservations;
        });
    }

    public static normalizeAddedTime(additionalTime: AdditionalTime[]) {
        let addedTime: AddedTime = {};
        additionalTime.forEach((item) => {
            const duration = S25Util.daysHoursMinutesToDuration({
                days: item.days,
                hours: item.hours,
                minutes: item.minutes,
            });
            switch (item.id) {
                case "setupStart":
                    addedTime.setup = duration;
                    break;
                case "preEventStart":
                    addedTime.preEvent = duration;
                    break;
                case "postEventEnd":
                    addedTime.postEvent = duration;
                    break;
                case "takeDownEnd":
                    addedTime.takedown = duration;
                    break;
            }
        });
        return addedTime;
    }

    public static normalizeObjectsData(sourceData: S25ObjectReservation[], itemType: ObjectType) {
        const objectRsrvArr = ["reserved", "draft", "requested"];
        const result: any[] = [];
        objectRsrvArr.forEach((type) => {
            sourceData?.forEach((obj: any) => {
                if (itemType === "location") {
                    if (obj[type]) {
                        obj[type].forEach((source: S25RmReservation) => {
                            let dataset = {
                                layoutId: parseInt(source?.layout?.itemId as any),
                                share: source.isShare,
                                instructions: source.instructions,
                                spaceId: source.itemId,
                                rating: source.rating,
                                attendance: source.attendance,
                            };
                            result.push(dataset);
                        });
                    }
                } else {
                    if (obj[type]) {
                        obj[type].forEach((source: S25RsReservation) => {
                            let dataset = {
                                quantity: source.quantity,
                                instructions: source.instructions,
                                resourceId: source.itemId,
                            };
                            result.push(dataset);
                        });
                    }
                }
            });
        });
        return result;
    }
}
