import { S25ProfileUtil } from "./s25.profile";
import { S25Profile } from "../ProfileI";
import { EventMircotI, ExpandedInfoI, WsDataI, S25Event } from "../EventMicroI";
import { S25Reservation } from "../ReservationI";

export class S25EventUtil {
    public static async setFromWS(WSEvent: WsDataI) {
        let wsExpandedInfo: ExpandedInfoI = WSEvent?.expandedInfo;
        let item: EventMircotI = WSEvent?.data?.items[0]; //TODO Looking into more, should pass by arrary instead
        let event: S25Event = {
            itemId: item.id,
            itemName: item.name,
            etag: item?.etag,
            state: item.context.state,
            profile: S25ProfileUtil.setFromWs(item.profiles, wsExpandedInfo, WSEvent?.approval),
            expandedInfo: WSEvent?.expandedInfo,
        };

        return Promise.resolve(event);
    }
    public static getAllOccurrences(event: S25Event) {
        let occurrences: S25Reservation[] = [];
        event.profile.forEach((p: S25Profile) => {
            occurrences = occurrences.concat(S25ProfileUtil.getAllReservations(p));
        });
        return occurrences || [];
    }

    public static async addOccurrence(reservation: S25Reservation, profile?: S25Profile) {
        if (!profile) profile = await S25ProfileUtil.createEmptyProfile(new Date(), new Date());
        S25ProfileUtil.addReservation(profile, reservation);
        return profile;
    }

    public static addProfile(event: S25Event, newProfile: S25Profile) {
        //TODO:  need to add real logic here
        event.profile.push(newProfile);
        return event;
    }

    /**
     * Returns an array of all locations on any occurrence of this event to be used as a summary
     */
    public static getLocations(event: S25Event) {}

    /**
     * Returns an array of all resources on any occurrence of this event to be used as a summary
     */
    public static getResources(event: S25Event) {}
}
