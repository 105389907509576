import { SpacePref } from "./Event";
import { S25Reservation } from "./ReservationI";
import { S25WsSpace } from "../../services/space.service";
import { WSReservations } from "./EventMicroI";

export type S25Profile = {
    itemId?: number; //profileId
    itemName?: string; //profile name
    profUse?: ProfileUseCode;
    spacePref?: SpacePref; //TODO: consider preferences being listed as a reservation but with a flag indicating preference type.
    comment?: string;
    commentId?: number;

    //Repeating info
    recTypeID?: RecordType;
    profileCode?: string;

    //// micro
    comments?: string;
    expectedCount?: number | null;
    name?: string;
    occurrenceDefn?: OccurrenceDef;
    profileId?: number;
    registeredCount?: number;
    reservations?: (S25Reservation | WSReservations)[]; // S25Reservation[];
};

export enum ProfileUseCode {
    Warning = -2,
    ExcludeDates = -1,
    IncludeDates = 0,
    Reservation = 1,
    Setup = 2,
    Takedown = 3,
    OverrideDate = 4,
    CancelledDate = 99,
}
export enum RecordType {
    SingleDateTime = 0,
    RecurrenceGrammar = 1,
    AdHoc = 2,
    FreeForm = 3,
}

//inherited properties from the parent folder/cabinet
export enum InheritCode {
    cancel = -1,
    local = 0,
    inherit = 1,
    template = 2,
}

export interface OccurrenceDef {
    adHocDates?: any[];
    addedTime?: AddedTime;
    initEndDt?: string;
    initStartDt?: string;
    profileCode?: string;
    recTypeId: RecordType;
}
export interface AddedTime {
    postEvent?: string;
    preEvent?: string;
    setup?: string;
    takedown?: string;
}

export type AdditionalTime = {
    id?: Ids;
    label: string;
    days?: number | null;
    hours?: number | null;
    minutes?: number | null;
    newDate?: Date;
};

export type Ids = "preEventStart" | "postEventEnd" | "setupStart" | "takeDownEnd";
